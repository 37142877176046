<template>
  <div class='MasterStudio'>
    <div class="titleBox">
      <h4>我的四名工作室</h4>
      <div v-for="(item,index) in selectArr" :key="index" @click="toSelect(item.id)" :class="active == item.id ? 'active' : 'noClass'">
        {{item.name}}
      </div>
    </div>
    <!-- 显示列表 -->
    <StudioCom v-if="active == 1" />
    <!-- 我的文章 -->
    <MyArticle v-if="active == 2" />
    <!-- 我的证书 -->
    <MyCertificate v-if="active == 3" />
  </div>
</template>
<script>
import StudioCom from '@/views/personal/components/StudioCom.vue'
import MyArticle from '@/views/personal/components/MyArticle.vue'
import MyCertificate from '@/views/personal/components/MyCertificate.vue'
export default {
  components: {
    StudioCom,
    MyArticle,
    MyCertificate
  },
  data() {
    return {
      active: 1,

      selectArr: [
        {
          id: 1,
          name: '四名工作室'
        },
        {
          id: 2,
          name: '我的文章'
        },
        {
          id: 3,
          name: '我的证书'
        },
      ]
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
    toSelect(id) {
      this.active = id;
    },

  },
}
</script>
<style lang="less" scoped>
.MasterStudio {
  width: 100%;
  padding: 39px 30px;
  background: #fff;
  .titleBox {
    display: flex;
    margin-bottom: 24px;
    .h4 {
      font-size: 16px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 700;
      margin-left: 20px;
    }
    .active {
      cursor: pointer;
      font-size: 16px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 700;
      color: #3489ff;
      margin-left: 20px;
    }
    .noClass {
      font-size: 16px;
      font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
      font-weight: 400;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
