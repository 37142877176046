<template>
  <div class='StudioCom'>
    <div class="showTab">
      <div class="forBox" v-for="(item,index) in 10" :key="index" @click="toDetail()">
        <div class="photo">
          <img src="" alt="">
        </div>
        <div class="info">
          <p>欧阳娜娜四名工作室测</p>
          <p class="phase">
            <span>小学</span>
            <span>语文</span>
          </p>
          <div class="infoList">
            <p>成员：123</p>
            <p>访问：1234</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="form.page"
        :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      List: [],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10
      }
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
    //跳转详情
    toDetail() {
      this.$router.push('')
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.init();
      console.log(`当前页: ${val}`);
    }
  },
}
</script>
<style lang="less" scoped>
.StudioCom {
  .showTab {
    display: flex;
    flex-wrap: wrap;

    .forBox {
      width: 337px;
      height: 152px;
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      box-shadow: 0px 0px 6px 2px rgba(113, 113, 113, 0.12);
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      padding: 15px 15px;
      margin-right: 20px;
      margin-bottom: 20px;
      .photo {
        width: 122px;
        height: 122px;
        img {
          width: 122px;
          height: 122px;
        }
      }
      .info {
        p:nth-of-type(1) {
          width: 159px;
          font-size: 16px;
          font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
          font-weight: 400;
          color: #333333;
        }
        .phase {
          display: flex;
          justify-content: space-between;
          color: #999999;
        }
        .infoList {
          p {
            color: #999999;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  //样式
  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
</style>
