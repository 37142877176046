<template>
  <!-- 我的文章 -->
  <div class='MyArticle'>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox">
          <span>状态：</span>
          <el-select v-model="value" placeholder="请选择状态" class="sid" style="width:200px">
            <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <span>文章标题：</span>
          <el-input v-model="input" placeholder="请输入文章标题关键字" class="input"></el-input>
          <span>文章分类：</span>
          <el-select v-model="value" placeholder="请选择文章分类" class="sid" style="width:200px;margin-left:10px">
            <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

        </div>
        <div class="rBox">
          <el-button type="primary">查询</el-button>
          <el-button>重置</el-button>

        </div>

      </div>
      <div class="titleBox">
        <div class="first">
          <el-button type="primary">批量删除</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :default-sort="{prop: 'date', order: 'descending'}" style="width: 1054px"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" align="center" width="70" />
          <el-table-column prop="date" label="文章标题" align="center" width="180" />
          <el-table-column prop="date" label="文章分类" align="center" width="140" />
          <el-table-column prop="date" label="所属工作室" align="center" width="140" />
          <el-table-column prop="date" label="评论数" sortable align="center" width="140" />
          <el-table-column prop="date" label="状态" align="center" width="180" />
          <el-table-column prop="date" label="操作" align="center">
            <span>查看</span>
            <span>删除</span>
          </el-table-column>

        </el-table>
      </div>
      <div class="block">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="form.page"
          :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: '',
      optiones: [
        {
          id: 0,
          name: '单选题'
        },
        {
          id: 1,
          name: '多选题'
        },
        {
          id: 2,
          name: '填空题'
        },
        {
          id: 3,
          name: '判断题'
        },
      ],

      input: '',
      tableData: [
        {
          data: 1
        }
      ],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10
      }
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.init();
      console.log(`当前页: ${val}`);
    }
  },
}
</script>
<style lang="less" scoped>
.MyArticle {
  //内容盒子
  .contentBox {
    padding: 26px 26px;
    .selectTop {
      display: flex;
      .lBox {
        .input {
          width: 186px;
          margin-left: 10px;
        }
        .sid,
        .input {
          margin-right: 15px;
        }
      }
      .rBox {
        margin-left: 30px;
      }
    }
    //按钮
    .titleBox {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }
    //表格
    .tableList {
      width: 1054px;
      margin-top: 24px;
      border: 1px solid #ebeef5;
      border-bottom: none;
      span {
        margin-right: 30px;
        color: #3489fe;
        cursor: pointer;
      }
    }
    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
