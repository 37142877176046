<template>
  <!-- 我的证书 -->
  <div class='MyCertificate'>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox">
          <span>状态：</span>
          <el-select v-model="value" placeholder="请选择状态" class="sid" style="width:200px">
            <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <span>证书类型：</span>
          <el-select v-model="value1" placeholder="请输入关键词" class="sid" style="width:200px;margin-left:10px">
            <el-option v-for="item in optiones1" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="rBox">
          <el-button type="primary">查询</el-button>
          <el-button>重置</el-button>

        </div>

      </div>

      <!-- 列表 -->
      <div class="ListBox">
        <div class="forBox" v-for="(item,index) in 10" :key="index">
          <!-- 左边盒子 -->
          <div class="leftBox">
            <div class="oneBox">
              <img src="" alt="">
            </div>
            <div class="twoBox">
              <h4>这里显示证书的标题占位测试效果仅供参考</h4>
              <div class="middle">
                <p>提交时间：2020-05-12 16:18</p>
                <p>
                  状态：<span>审核中</span>
                </p>
              </div>
              <p class="last">
                <span>证书类型：xxx</span>
                <span>所属工作室：林夏四名工作室</span>
              </p>
            </div>
          </div>
          <!-- 右边盒子 -->
          <div class="rightBox">

          </div>

        </div>
      </div>

      <div class="block">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="form.page"
          :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      MyCertificate: [],
      value: '',
      optiones: [
        {
          id: 0,
          name: '单选题'
        },
        {
          id: 1,
          name: '多选题'
        },
        {
          id: 2,
          name: '填空题'
        },
        {
          id: 3,
          name: '判断题'
        },
      ],
      value1: '',
      optiones1: [
        {
          id: 0,
          name: '单选题'
        },
        {
          id: 1,
          name: '多选题'
        },
        {
          id: 2,
          name: '填空题'
        },
        {
          id: 3,
          name: '判断题'
        },
      ],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10
      }
    }
  },
  created() {
  },
  computed: {
  },
  methods: {

    handleSizeChange(val) {
      this.form.limit = val;
      this.init();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.init();
      console.log(`当前页: ${val}`);
    }
  },
}
</script>
<style lang="less" scoped>
.MyCertificate {
  //内容盒子
  .contentBox {
    padding: 26px 26px;
    .selectTop {
      display: flex;
      .lBox {
        .input {
          width: 186px;
          margin-left: 10px;
        }
        .sid,
        .input {
          margin-right: 15px;
        }
      }
      .rBox {
        margin-left: 30px;
      }
    }
    //列表
    .ListBox {
      .forBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #f0f0f0;
        padding: 14px;
        margin-bottom: 20px;
        margin-top: 24px;
        .leftBox {
          display: flex;
          align-items: center;
          .oneBox {
            width: 176px;
            height: 128px;
            border-radius: 4px;
            img {
              width: 176px;
              height: 128px;
              border-radius: 4px;
            }
          }
          .twoBox {
            margin-left: 19px;
            h4 {
              width: 304px;
              font-size: 16px;
              font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
              font-weight: 700;
              color: #3489fe;
              margin-bottom: 10px;
            }
            .middle {
              p {
                font-size: 14px;
                font-family: Microsoft YaHei Regular,
                  Microsoft YaHei Regular-Regular;
                font-weight: 400;
                color: #666666;
                margin-bottom: 5px;

                span {
                  color: #ff8201;
                }
              }
            }
            .last {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              font-family: Microsoft YaHei Regular,
                Microsoft YaHei Regular-Regular;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .rightBox {
        }
      }
    }
    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
